<template>
  <div class="row page_header">
    <div class="col-12">            
    </div>
  </div>
  <div class="row page_content">
    <div class="col-12">
      <SlideShow />
    </div>
  </div>
</template>

<script>
import SlideShow from "@/components/AnnouncementSlider.vue";

export default {
  name: "DisplayView",
  components: {
    SlideShow,
  },
  data(){

  },
  mounted(){
    this.$store.state.pageName = "Annonces"

  }
};
</script>

<style>
.slider-frame {
  max-height: 600px;
}
.announcement_category{
  font-size: 400%;
}
.page_header{
    height: 20%;
}
.page_content{
    height: 80%;
}
</style>