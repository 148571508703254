<template>
<div class="container">

    <div class="row">
    <div class="col-md-2 col-lg-2"></div>

    <div align="left" class="col-md-8 col-lg-8 col-sm-8 card card-form">
        <div v-for="book in books" v-bind:key="book">
            <div v-on:click="openBook(book.id)" class="card bg-light text-dark">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-9 col-lg-9 col-sm-12">
                            <div class="text-uppercase"><h3>{{book.title}}</h3></div>
                            <div>{{book.authors + " / " + book.year}}</div>
                            <div class="text-black-50">{{book.category + " / "+ book.domain}}</div>
                        </div>
                        <div class="col-md-3 col-lg-3 col-sm-12">
                            <a v-bind:href="'https://back-notodji.lome-bs.com/my_app/storage/app/public/' + book.url" target="_blank"><button class="btn btn-primary">Télécharger</button></a>
                        </div>
                    </div>

                </div>
            </div>
            <br>
        </div>
    </div>
    <div class="col-md-2 col-lg-2"></div>

    </div> 
</div>

</template>

<script>

import axios from "axios";

export default {
name: 'BooksDomains',
components: {},
data() {
    return {
    books: null,
    category_id: 0,
    domain_id: 0,

    };
},
mounted() {
    this.$store.state.pageName = "Documents";
    this.getBooks();

},

methods: {

    getBooks(){
        
        axios.post('api/books/get-by-category-and-domain',{
            book_category: this.$route.params.categoryId,
            book_domain: this.$route.params.domainId
        }).then(
            result => {
                this.books = result.data;
                console.log(result.data);
            });
    },

    openBook(bookId){

    }

}
}
</script>

<style>
.slider-frame {
max-height: 600px;
}
</style>