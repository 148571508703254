<template>
  <div class="">
    <div class="row">
      <div class="col-lg-10">
        <!-- <h1>Plannings</h1> -->
      </div>

      <!-- <div class="col-lg-2">
        <button
            class="btn btn-primary mr-1"
            type="button"
            v-on:click="addPlanning"
        >
        <i class="fas fa-calendar"></i>
          Nouveau planning
        </button>
      </div> -->
    </div>
    <br>
    <div class="row">
      <div class="col-md-12">
        <button
            class="btn btn-primary mr-1"
            type="button"
            v-on:click="getAllDelays">
        <i class="fa-refresh"></i>
          Actualiser
        </button><hr>

        <div class="table-responsive">
          <table style="width:100%;" class="table table-hover table-sm table-bordered table-striped">
            <thead class="thead-dark">
            <tr>
              <th>N°</th>
              <th style="width:10%;">Matricule</th>
              <th style="width:25%;">Nom et prénoms</th>
              <th style="width:13%;">Date</th>
              <th style="width:13%;">Heure d'arrivée</th>
              <th style="width:13%;">Début du cours</th>
              <th style="width:25%;">Motif</th>
            </tr>
            </thead>

            <tbody>
            <tr style="text-align:left;" v-for="d in delays" v-bind:key="d">
              <td>{{  }}</td>
              <td>{{ d.student_reference_number }}</td>
              <td>{{ d.first_name }} {{ d.last_name }}</td>
              <td>{{ d.date }}</td>
              <td>{{ d.time }}</td>
              <td>{{ d.start_time }}</td>
              <td class="text-lg-left">{{ d.reason }}</td>
              
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {

  data() {
    return {
      delays: [],
      date: null,
      
    };
  },

  mounted() {
    this.$store.state.pageName = "Retardataires";
    this.getAllDelays();
  },

  methods: {
    getAllDelays() {
      axios.get('api/delay/get-all').then(
          result => {
            this.delays = result.data.delays;
          }
      )
    },
    getDelaysByDate(date) {
      axios.get('api/delay/get-by-date/'+this.date).then(
          result => {
            this.levels = result.data.levels;
          }
      )
    }
    
  },
};
</script>

<style>

</style>