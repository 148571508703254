<template>
    <div>
        <br/>
        <div class="row">
            <div class="col-md-2 col-sm-2">
                <a href="#" class="logo">
                <span><img class="logo_img" src="images/logo_10_ans.png" alt=""/></span>
                </a>
            </div>
            <div class="col-md-8 col-sm-10">
                <div class="row display-4">
                    <div class="col-12">
                        <div>{{this.$store.state?.pageName}}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <!-- <h3 v-if="this.$store.state.user.profile === null" >Retard : {{delay}}</h3>     -->
            </div>          
        </div>
    </div>
</template>

<script>
export default {
    name: 'Header',
    data(){
        return {
            pageName : "Nom de la page",
            delay : 0,

        }
    },
    
    methods: {
        logout(){
            
        },
        login(){

        },
        changePageName(e){
            this.pageName = e;
        },
        getDelayByStudent(){

        }
        

    }
}
</script>