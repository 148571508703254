<template>
  <div>
    <Header/>
    <div class="row">
      <div class="col-2"></div>
      <div class="col-sm-12 col-md-4">
        <form v-if="code_sent_status==1" @submit.prevent="defineNewPassword">
            
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p>
                        Veuillez consulter votre boite mail LBS.<br>
                        Le code de réinitilisation vous a été envoyé!<br> 
                        Entrez le code de réinitialisation puis définissez votre nouveau mot de passe.
                    </p>
                </div>
            </div>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                <span class="input-group-text">Code</span>
                </div>
                <input class="form-control" type="text" placeholder="Ex: AXff56" v-model="reset_code"/>
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">Mot de passe</span>
                </div>
                <input class="form-control" type="password" placeholder="Mot de passe" v-model="password"/>
            </div>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">Retaper mot de passe</span>
                </div>
                <input class="form-control" type="password" placeholder="Mot de passe" v-model="confirm_password"/>
            </div>
            <a href="#" v-on:click="resendCode"><span>Renvoyer le code</span></a>
            <br><br>
            <button type="submit" class="btn btn-primary btn-lg">Enregistrer</button>
            <br>

        </form>
        <form v-else @submit.prevent="sendResetCode">
            
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <p>
                        Veuillez entrer votre mail LBS pour rénitialiser votre mot de passe NOTODJI.
                    </p>
                </div>
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                <span class="input-group-text">Email</span>
                </div>
                <input class="form-control" v-on:keyup="checkEmail($event.target.value)" type="text" placeholder="prenom.nom@lomebs.com" v-model="email"/>

            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                <span class="input-group-text">Nom et prénoms</span>
                </div>
                <input v-model="student" class="form-control" placeholder="Nom et prénoms" type="text" disabled>
            </div>

            <button type="submit" class="btn btn-primary btn-lg">Envoyer le code</button>
            <br>

        </form>
        <hr>
      </div>
      <div class="col-sm-12 col-md-4">
        <img class="img-fluid" src="images/citation.jpg" alt="">
      </div>
      <div class="col-md-2"></div>
    </div>
    
  </div>
</template>

<script>
import axios from "axios";
import Header from "@/components/Header.vue";

export default {
  components:{
    Header
  },
  data() {
    return {

      email: null, 
      password: null,
      confirm_password: null,
      reset_code: null,
      errors: null,
      errorMsg: null,
      code_sent_status: 0,
      student: null,
    };
  },
  mounted(){
    this.$store.state.pageName = "Réinitialisation du mot de passe"

  },

  methods: {
    resendCode(){
        this.code_sent_status = 0
    },
    sendResetCode(){
        axios.post('api/student/send-reset-code',{email: this.email}).then(response=>{
            
        });
      this.code_sent_status = 1;
      
    },

    checkEmail(email){
        axios.post('api/student/email-check',{email: email}).then(response=>{
            if(response.data.student != null){
                this.student = response.data.student.first_name+" "+response.data.student.last_name;
            }else{
                this.student = " ";
            }
        });
    },

    defineNewPassword(){
        var pass = this.password.trim();
        var conf = this.confirm_password.trim();
        var code = this.reset_code.trim();


      
      if (pass.length != 0 && conf.length != 0 && pass === conf) {
        axios.post('api/student/password-reset', {reset_code: code, password: pass, confirm_password: conf})
        .then(response=>{
            alert(response.data.message);

            if(response.data.success === 0){
                this.password = null;
                this.confirm_password = null;
                this.reset_code = null;
            }else{
                this.$router.push("/login");
            }
        })
        
      } else {
            alert("Les mots de passes de correspondent pas");
      }
    },
    
    openDelayForm(){
      this.$router.push("/create-delay");

    }
  },
};
</script>