<template>
    <carousel :items-to-show="1" :autoplay="20000" :wrap-around="true" :snap-align="center">
      <slide class="carousel_img" v-for="planning in plannings" v-bind:key="planning">
        <img class="" v-bind:src="'https://back-notodji.lome-bs.com/my_app/storage/app/public/' + planning.image_url">
        
      </slide>
      <template #addons>
        <navigation />
        <pagination />
      </template>
    </carousel>
  
    <carousel :items-to-show="1" :autoplay="50000" :wrap-around="true" :snap-align="center">
      <slide class="marquee-message" v-for="planning in plannings" v-bind:key="planning">
        <marquee>
          {{planning.message}}
        </marquee>
      </slide>
      
      <template #addons>
        <navigation />
        <pagination />
      </template>
    </carousel>
  
  
  
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import {Carousel, Slide} from 'vue3-carousel';
import MarqueeText from 'vue-marquee-text-component'
import axios from 'axios';

export default {
  name: "SlideShow",
  components: {
    Carousel,
    Slide,
    
    MarqueeText,
  },

  data() {
    
    return {
      
      plannings:[],
    };
  },
  mounted() {
    this.getPlannings();
  },
  methods: {
    getPlannings() {
      axios.get("/api/plannings/all").then((response) => {
        console.log(response);
        this.plannings = response.data.plannings;
        // console.log(process.env.VUE_APP_LOCAL_API_URL);

      });
    },
  },
};
</script>

<style scoped>
.marquee-message{
  position: relative;
  bottom: 0px;
  z-index: 1;
  background-color: darkblue;
  color: white;
  font-size: 200%;
}

.carousel_img img {
  height: 200px;
}
.carousel-item img{
  height:100px!important ;
}

</style>