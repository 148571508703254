<template>
  <div>
    <div class="row">
      <div class="col-10"></div>
      <div class="col-2">
        <button class="btn btn-primary mr-1" type="button" v-on:click="addNote">
          <i class="fas fa-arrow-trend-up"></i>
          Ajouter des notes
        </button>
      </div>
    </div>
    
    <br />
    <div class="row">
      <div class="col-12">
        <table class="table table-hover">
          <thead class="thead-dark">
            <tr>
              <th>Matricule</th>
              <th>Nom et prénoms</th>
              <th>Module</th>
              <th>Devoir (40%)</th>
              <th>Examen (60%)</th>
              <th>Moyenne / 20</th>
              <th>Résultat</th>
              <!-- <th>Mention</th> -->
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="result in results"
              v-bind:key="result"
              :class="[
                result.result == 'Admis' ? 'table-success' : 'table-danger',
              ]"
            >
              <td style="text-align: left">{{ result.student_reference_number }}</td>
              <td style="text-align: left">{{ result.last_name + ' ' + result.first_name }}</td>
              <td style="text-align: left">{{ result.module }}</td>
              <td>{{ result.assignment_mark * 100 / 40 }}</td>
              <td>{{ result.exam_mark * 100 / 60 }}</td>
              <td>{{ result.average }}</td>
              <td>{{ result.result }}</td>
              <!-- <td>{{result.appreciation}}</td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "NoteList",
  data(){
    return {
      results: null,
      
    }
  },

  mounted() {
    this.$store.state.pageName = "Résultats académiques";
    this.getAllResults();
  },

  methods: {
    addNote() {
      this.$router.push("/import-results");
    },

    getAllResults() {
      axios.get('/api/result/get-result').then(result=>{
        
        this.results = result.data.results;
      });
    },
  },
};
</script>

<style scoped>
</style>