<template>
  <div class="">
    <div class="row">
      <div class="col-lg-10">
        <!-- <h1>schoolFees</h1> -->
      </div>

      <div class="col-lg-2">
        <button
            class="btn btn-primary mr-1"
            type="button"
            v-on:click="addSchoolFees"
        >
            <i class="fas fa-money-bill"></i>Nouveau paiement
        </button>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table table-hover table-sm table-bordered table-striped">
            <thead class="thead-dark">
            <tr>
              <th>Matricule</th>
              <th>Nom complet</th>
              <th>Montant payé</th>
              <th>Date de paiement</th>
              <th>Actions</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="sf in schoolFees" v-bind:key="sf">
              <td>{{ sf.student_reference_number }}</td>
              <td style="text-align: left;">{{ sf.last_name }} {{ sf.first_name }}</td>
              <td>{{ sf.total_paid }}</td>
              <td> {{ sf.payment_date }}</td>
              <td class="">
                <a href="#" v-on:click="updateSchoolFees(sf.id)"><i class="fas fa-pen"></i></a>
                |
                <a style="color: red;" href="#" v-on:click="deleteSchoolFees(sf.id)"><i class="fas fa-trash"></i></a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SchoolFeesList",
  props: ['school_fees_id'],
  data() {
    return {
      schoolFees: [],
      deleteResult: null,
      status: null,
      message: null,
    };
  },

  mounted() {
    this.$store.state.pageName = "Scolarité";
    this.getSchoolFees()
  },

  methods: {
    addSchoolFees() {
      this.$router.push("/admin-school-fees-create");
    },

    updateSchoolFees(id) {
      this.$router.push({
        name: "update-school-fees", params: {
          school_fees_id: id
        }
      });
    },

    getSchoolFees() {
      axios.get("/api/school-fees/all").then((schoolFees) => {
        this.schoolFees = schoolFees.data.schoolFees;
      });
    },

    deleteSchoolFees(id) {

      if (confirm("Etes-vous sûr de vouloir supprimer cet élément ?")) {
        axios.get("api/school-fees/delete/" + id).then((result) => {
          this.deleteResult = result.data;
          console.log(result.data);

          if (result.data.status === 200) {
            console.log(result.data.message)
            this.$router.push("/admin-school-fees");
            this.getSchoolFees()
            alert(result.data.message);
          } else {
            console.log(result.data.message)
          }
        });

      }

    },
  },
};
</script>

<style scoped>
</style>