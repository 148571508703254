<template>
  <div id="exampleModal" aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" role="dialog"
       tabindex="-1">
    <div class="modal-dialog" role="document">
      <form enctype="multipart/form-data" v-on:submit.prevent="importSchoolFees">

        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">Importer depuis un fichier excel</h5>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <div class="custom-file">
              <input id="fichier" ref="file" class="custom-file-input" type="file" v-on:change="handleFile">
              <label class="custom-file-label" for="customFile" v-if="file">{{file.name}}</label>
              <label class="custom-file-label" for="customFile" v-else>Sélectionner un fichier excel</label>
            </div>

          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" type="submit">Charger</button>
            <button class="btn btn-secondary" data-dismiss="modal" type="button">Annuler</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-md-4 col-lg-3">
        <button class="btn btn-primary" data-target="#exampleModal" data-toggle="modal"
                type="button">Importer un fichier excel
        </button>

      </div>

      <div class="col-md-4 col-lg-6 col-sm-12 card card-form">
        <form enctype="multipart/form-data" v-on:submit.prevent="savePayment">

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Matricule</span>
            </div>
            <input v-model="reference_number" class="form-control" placeholder="22B1SI098" type="text">
          </div>

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Année académique</span>
            </div>
            <input v-model="academic_year" class="form-control" placeholder="2021 - 2022" type="text">
          </div>


          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Nom</span>
            </div>
            <input v-model="last_name" class="form-control" placeholder="KOKOU" type="text">
          </div>


          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Prénom(s)</span>
            </div>
            <input v-model="first_name" class="form-control" placeholder="Koffi Kodjo" type="text">
          </div>


          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Montant payé</span>
            </div>
            <input v-model="total_paid" class="form-control" placeholder="100000" type="number">
          </div>

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Date de paiement</span>
            </div>
            <input v-model="payment_date" class="form-control" placeholder="12" type="date">
          </div>
          <br>

          <button class="btn btn-primary btn-lg" type="submit">Ajouter</button>

        </form>
      </div>

      <div class="col-md-4 col-lg-3"></div>

    </div>
  </div>

</template>

<script>

import axios from "axios";

export default {
  name: "ImportationForm",
  data() {
    return {
      reference_number: null,
      first_name: null,
      last_name: null,
      academic_year: null,
      total_paid: 0,
      payment_date: 0,
    };
  },

  mounted() {
    this.$store.state.pageName = "Scolarité";
  },

  methods: {
    getLevels() {
      axios.get('api/levels/all',).then(
          result => {
            this.levels = result.data.levels
          }
      )
    },

    importSchoolFees() {

      let formData = new FormData();

      formData.append('fichier', this.file);
      console.log(formData);
      axios.post('api/school-fees/store', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(
          result => {
            alert(result.data.message)
          }
      )
    },

    savePayment() {
      let formData = new FormData();

      formData.append('student_reference_number', this.reference_number);
      formData.append('academic_year', this.academic_year);
      formData.append('first_name', this.first_name);
      formData.append('last_name', this.last_name);
      formData.append('payment_date', this.payment_date);
      formData.append('total_paid', this.total_paid);

      axios.post('api/school-fees/create', formData).then(
          result => {
            alert(result.data.message);
            this.$router.push("/admin-school-fees");
          }
      )

    },
    handleFile() {
      this.file = this.$refs.file.files[0];
    },

  }
}
</script>

<style scoped>

</style>