<template>
  <div>
    <hr />
    <div class="row">
      <div class="col-md-2 col-sm-2"></div>
      <!-- <div class="col-md-4 col-sm-4">
        <form class="form-inline" action="">
          <label for="acadmeic_year">Année:</label>
          <select class="form-control" id="sel1">
            <option>2021-2022</option>
            <option>2020-2021</option>
          </select>
        </form>
      </div> -->
      
      <div v-for="fees in totalFeesPaid" v-bind:key="fees" style="box-shadow: 2px 2px 5px #888888; font-size: 150%; background-color: #ff5252; color: white;" class="col-md-4 col-sm-4">Total {{fees.academic_year}}: <span style="font-weight: 900;">{{fees.total_paid}}</span> FCFA</div>

      
      <div class="col-md-2 col-sm-2"></div>
    </div>
    <br />
    <div class="row">
      <div class="col-12">
        <table class="table table-hover table-striped">
          <thead class="thead-dark">
            <tr>
              <th>Date de paiement</th>
              <th>Montant</th>
              <th>Année scolaire</th>
              <th>Commentaire</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="schoolFees in schoolFeesHistory" v-bind:key="schoolFees">
              <td>{{ schoolFees.payment_date }}</td>
              <td>{{ schoolFees.total_paid }}</td>
              <td>{{ schoolFees.academic_year }}</td>
              <td>{{ schoolFees.comment }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import SlideShow from '@/components/SlideShow.vue';

export default {
  name: "DisplayView",
  components: {
    // SlideShow
  },
  data() {
    return {
      schoolFeesHistory: null,
      remainingFee: null,
      totalFeesPaid: null,
      studentId: this.$store.state.user.user.student_reference_number,
    };
  },
  mounted() {
    this.$store.state.pageName = "Etat de la scolarité";
    this.getSchoolFeesHistory();
    this.getTotalPaid();
    // this.getRemainingFees();
  },
  methods: {
    getSchoolFeesHistory() {
      axios
        .post("/api/school-fees/get-school-fees-by-student-id/" + this.studentId)
        .then((result) => {
          this.schoolFeesHistory = result.data.school_fees;
          // this.remainingFee = this.getRemainingFees();
          console.log(this.schoolFeesHistory);
        });
    },

    getTotalPaid(){
      axios.post("/api/school-fees/get-total-paid-by-student-id-by-year/" + this.studentId)
      .then((result)=>{
        this.totalFeesPaid = result.data.total_paid;

      })
    },

    getRemainingFees() {
      const dueSchoolFees = [
        800000, 800000, 800000, 800000, 800000, 800000, 1000000, 1000000,
        1200000, 1200000,
      ];
      let totalPaid = 0;
      this.schoolFeesHistory.forEach((schoolFee) => {
        totalPaid += schoolFee.total_paid;
      });
      return dueSchoolFees[this.$store.state.user.user.level_id] - totalPaid;
    },
  },
};
</script>

<style>
</style>