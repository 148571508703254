<template>
  <div>
    <div class="row sticky">
      <div align="left" class="col-6">
        <a class="sticky-text" href="#">NOTODJI</a>
      </div>
      <div v-if="this.$store.state.authenticated" class="col-6 login-infos">
        <a class="sticky-text" href="#">Bonjour {{ this.$store.state.user.user.first_name }} |</a>
        <a class="sticky-text" @click.prevent="logout" href="#"> Déconnexion</a>
      </div>
      <div v-else class="col-6 login-infos">
        <a class="sticky-text" @click.prevent="login" href="#"> Connexion</a>
      </div>
    </div>
    <div class="page-content">
      <Header />

      <router-view> </router-view>
    </div>
    <button v-if="this.$store.state.authenticated" @click.prevent="redirectToHome" class="btn btn-primary home-button">
      <i class="fa fa-home"></i>Accueil
    </button>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import axios from "axios";

export default {
  name: "Master",
  components: {
    Header,
  },

  mounted() {},

  methods: {
    redirectToHome() {
      this.$router.push("dashboard");
    },

    logout() {
      this.$store.commit("logout");
      window.localStorage.removeItem("auth");
      this.$router.push("login");
    },

    login(){
      this.$router.push("login");
    }
  },
};
</script>