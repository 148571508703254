<template>
  <div class="container">

    <div class="row">
      <div class="col-md-4 col-lg-3"></div>

      <div class="col-md-4 col-lg-6 col-sm-12 card card-form">
        <form enctype="multipart/form-data" v-on:submit.prevent="createPlanning">

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Niveau</span>
            </div>
            <select v-model="level" class="custom-select">
              <option v-for="level in levels" v-bind:key="level" v-bind:value="level.id">{{ level.label }}</option>
            </select>
          </div>

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Date de debut</span>
            </div>
            <input v-model="start_date" class="form-control" placeholder="06/12/2022" type="date">
          </div>

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Date de fin</span>
            </div>
            <input v-model="end_date" class="form-control" placeholder="06/12/2022" type="date">
          </div>

          <div class="input-group mb-3">
            <textarea name="message" id="message" v-model="message" class="form-control" placeholder="Saisissez un message " cols="30" rows="10"></textarea>
          </div>

          <div class="custom-file">
            <input id="image" ref="file" class="custom-file-input" type="file" v-on:change="handleFile">
            <label class="custom-file-label" for="customFile" v-if="file">{{file.name}}</label>
            <label class="custom-file-label" for="customFile" v-else>Joindre l'image</label>
          </div>
          
          <br>
          <br>
          <div align="left" class="custom-control custom-checkbox">
            <input id="customCheck" v-model="published" class="custom-control-input" type="checkbox" value="">
            <label class="custom-control-label" for="customCheck">Publier ?</label>
          </div>
          <br>

          <button class="btn btn-primary btn-lg" type="button" v-on:click="createPlanning">Ajouter</button>

        </form>
      </div>
      <div class="col-md-4 col-lg-3"></div>

    </div> 
  </div>

</template>

<script>

import axios from "axios";

export default {
  name: 'CreatePlanning',
  components: {},
  data() {
    return {
      levels: null,
      level: 0,
      start_date: null,
      end_date: null,
      message: null,
      image: null,
      published: false,
      published_status: 0,
      file: null,
      admin_id: null
    };
  },
  mounted() {
    this.$store.state.pageName = "Ajouter un planning";
    this.getLevels();

  },

  methods: {
    createPlanning() {
      let formData = new FormData();
      this.published ? this.published_status = 1: this.published_status = 0;

      formData.append('level_id', this.level);
      formData.append('start_date', this.start_date);
      formData.append('end_date', this.end_date);
      formData.append('message', this.message);
      formData.append('published', this.published_status);
      formData.append('image', this.file);
      formData.append('admin_id', 1);
      console.log(this.published_status);

      axios.post('api/planning/store', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(
          result => {
            alert(result.data.message);
            this.file = null;
            this.$router.push("/admin-plannings");
          }
      )
    },

    handleFile() {
      this.file = this.$refs.file.files[0];
    },

    getLevels() {
      axios.get('api/levels/all',).then(
          result => {
            this.levels = result.data.levels;
          }
      )
    }
  }
}
</script>

<style>
.slider-frame {
  max-height: 600px;
}
</style>