import axios from 'axios'
import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {VueAgile} from 'vue-agile'


axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://back-notodji.lome-bs.com/';
// axios.defaults.baseURL = 'http://192.168.1.85:8000/';

createApp({
    extends: App,
    beforeCreate() {
        this.$store.commit('initializeAuth')
    }
}).use(store).use(router).use(VueAgile).mount('#app')
