<template>
  <div className="">
    <div className="row">
      <div className="col-lg-10">
      </div>

      <div className="col-lg-2">
        <button
            className="btn btn-primary mr-1"
            type="button"
            v-on:click="addAnnouncement"
        >
          Ajouter
        </button>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive">
          <table className="table table-hover table-sm table-bordered">
            <thead className="thead-dark">
            <tr>
              <td>Categories</td>
              <td>Description</td>
              <td>Périodes</td>
<!--              <td>Types</td>-->
              <td>Publié</td>
              <td>Actions</td>
            </tr>
            </thead>

            <tbody>
            <tr v-for="a in announcements" v-bind:key="a">
              <td>{{ a.title }}</td>
              <td>{{ a.description }}</td>
              <td><b>De</b> {{ a.start_date }} <b>à</b> {{ a.end_date }}</td>
              <!--              <td>{{ a.type }}</td>-->
              <td>
                  <span className="badge badge-pill badge-primary">{{
                      a.published == 1 ? "Oui" : "Non"
                    }}</span>
              </td>
              <td className="text-lg-left">
                <a href="#" v-on:click="updateAnnouncement(a.id)"><i class="fas fa-pen"></i></a> | 
                <a style="color: red;" href="#" v-on:click="deleteAnnouncement(a.id)"><i class="fas fa-trash"></i></a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AnnouncementList",
  props: ['announcement_id'],
  data() {
    return {
      announcements: [],
      deleteResult: null,
      status: null,
      message: null,
    };
  },

  mounted() {
    this.$store.state.pageName = "Annonces";
    this.getAnnouncements();
  },

  methods: {
    addAnnouncement() {
      this.$router.push("/create-announcement");
    },

    updateAnnouncement(id) {
      console.log("list " + id);
      this.$router.push({
        name: "update-announcement", params: {
          announcement_id: id
        }
      });
    },

    getAnnouncements() {
      axios.get("/api/announcements/all").then((announcements) => {
        this.announcements = announcements.data.announcements;
      });
    },

    deleteAnnouncement(id) {
      if (confirm("Etes-vous sûr de vouloir supprimer cet élément ?")) {
        axios.get("/api/announcement/delete/" + id).then((result) => {
          this.deleteResult = result.data;
          console.log(result.data);

          if (result.data.status === 200) {
            console.log(result.data.message)
            this.$router.push("/admin-announcements");
            alert(result.data.message);
            this.getAnnouncements();
          } else {
            console.log(result.data.message)
          }
        });
      }
    },
  },
};

</script>

<style scoped>
</style>