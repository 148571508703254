<template>
<div class="container">

    <div class="row">
    <div class="col-md-4 col-lg-3"></div>

    <div class="col-md-4 col-lg-6 col-sm-12 card card-form">
        <form enctype="multipart/form-data" v-on:submit.prevent="createBook">
        
        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <span class="input-group-text">Titre</span>
            </div>
            <input v-model="title" class="form-control" placeholder="Entrez le titre du document" type="text">
        </div>

        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <span class="input-group-text">Auteurs</span>
            </div>
            <input v-model="authors" class="form-control" placeholder="Entrez les noms des auteurs" type="text">
        </div>

        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <span class="input-group-text">Année</span>
            </div>
            <input v-model="year" class="form-control" placeholder="Année de sortie du document" type="number">
        </div>

        <div class="input-group mb-3">
            <div class="input-group-prepend">
            <span class="input-group-text">Categorie</span>
            </div>
            <select v-model="category" class="custom-select">
                <option v-for="category in categories" v-bind:key="category" v-bind:value="category.id">{{ category.label }}</option>
            </select>
        </div>
        
        <div class="input-group mb-3">
            <div class="input-group-prepend">
            <span class="input-group-text">Domaine</span>
            </div>
            <select v-model="domain" class="custom-select">
                <option v-for="domain in domains" v-bind:key="domain" v-bind:value="domain.id">{{ domain.label }}</option>
            </select>
        </div>

        <!-- <div class="input-group mb-3">
            <textarea name="message" id="message" v-model="message" class="form-control" placeholder="Saisissez un message " cols="30" rows="10"></textarea>
        </div> -->

        <div class="custom-file">
            <input id="document" ref="file" class="custom-file-input" type="file" v-on:change="handleFile">
            <label class="custom-file-label" for="customFile" v-if="file">{{file.name}}</label>
            <label class="custom-file-label" for="customFile" v-else>Joindre le fichier</label>
        </div>
        
        <br>
        <br>

        <button class="btn btn-primary btn-lg" type="button" v-on:click="createBook">Ajouter</button>

        </form>
    </div>
    <div class="col-md-4 col-lg-3"></div>

    </div> 
</div>

</template>

<script>

import axios from "axios";

export default {
name: 'CreateBook',
components: {},
data() {
    return {
    title: '',
    authors: '',
    year: 0,
    category: 0,
    domain: 0,
    document: null,
    file: null,
    admin_id: null,
    categories: null,
    domains: null
    };
},
mounted() {
    this.$store.state.pageName = "Ajouter un document";
    this.getCategories();
    this.getDomains();
},

methods: {
    createBook() {
    let formData = new FormData();
    //   this.published ? this.published_status = 1: this.published_status = 0;

    formData.append('title', this.title);
    formData.append('authors', this.authors);
    formData.append('year', this.year);
    formData.append('category', this.category);
    formData.append('domain', this.domain);
    formData.append('document', this.file);
    formData.append('admin_id', 1);

    axios.post('api/book/store', formData, {
        headers: {
        'Content-Type': 'multipart/form-data'
        }
    }).then(
        result => {
            alert(result.data.message);
            this.file = null;
            this.$router.push("/create-book");
        }
    )
    },

    handleFile() {
    this.file = this.$refs.file.files[0];
    },

    getCategories() {
    axios.get('api/book-categories/index',).then(
        result => {
            this.categories = result.data;
        }
    )
    },

    getDomains() {
    axios.get('api/book-domains/index',).then(
        result => {
            this.domains = result.data;
        }
    )
    }
}
}
</script>

<style>
.slider-frame {
max-height: 600px;
}
</style>