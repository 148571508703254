<template>
  <div>
    <Header/>
    <div class="row">
      <div class="col-2"></div>
      <div class="col-sm-12 col-md-4">
        <form @submit.prevent="login">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">@</span>
            </div>
            <input class="form-control" type="email" placeholder="email" v-model="email"/>
            <!-- <span v-if="errors">{{ errors.email }}</span> -->
          </div>

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">@</span>
            </div>
            <input class="form-control" type="password" placeholder="Mot de passe" v-model="password"/>
            <!-- <span v-if="errors">{{ errors.password }}</span> -->
          </div>
          <div>
            <a href="#" v-on:click="resetPassword"><span>J'ai oublié mon mot de passe</span></a>
          </div><br>

          <button type="submit" class="btn btn-primary btn-lg">Connexion</button>
          <br>
          <br>
          <button type="button" v-on:click="openDelayForm" class="btn btn-danger btn-lg">Formulaire de retard</button>
        </form>
        <hr>
      </div>
      <div class="col-sm-12 col-md-4">
        <img class="img-fluid" src="images/citation.jpg" alt="">
      </div>
      <div class="col-md-2"></div>
    </div>
    
  </div>
</template>

<script>
import axios from "axios";
import Header from "@/components/Header.vue";

export default {
  components:{
    Header
  },
  data() {
    return {

      email: null, 
      password: null,
      errors: null,
      errorMsg: null,
    };
  },
  mounted(){
    this.$store.state.pageName = "Login"

  },

  methods: {
    resetPassword(){
      //ouverture page reset
      this.$router.push("/password-reset");
      
    },

    login(){
      
      if (this.email.length > 0 && this.password.length > 0) {
        axios.get("/sanctum/csrf-cookie").then(response=>{
          axios.post('api/student/login', {email: this.email, password: this.password}).then(response =>{
            
            if (response.data.status.success) {
              this.$store.commit('setAuth', response.data.user);
              this.$store.state.admin = 0;
              this.$router.push("/dashboard");
            } else {
              this.errors = response.data.errors;
              // console.log(this.errors);
              alert("Email ou mot de passe incorrects");
            }
          });
        });
        
      } else {
        this.errorMsg = "Email ou mot de passe non valide";
        // this.errors.password = "Mot de passe non valide";
        // console.log(this.errors);
        alert(this.errorMsg);

      }
    },
    
    openDelayForm(){
      this.$router.push("/create-delay");

    }
  },
};
</script>