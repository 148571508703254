import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Master from '../views/Master.vue'
import PlanningDisplay from '../views/planning/DisplayView.vue'
import AnnouncementDisplay from '../views/announcement/DisplayView.vue'
import SchoolFeesDisplay from '../views/school_fees/DisplayView.vue'
import AcademicResultsDisplay from '../views/results/DisplayView.vue'
import StudentAnnouncement from '../views/announcement/StudentAnnouncement.vue'
import StudentPlanning from '../views/planning/StudentPlanning.vue'
import PlanningCreateForm from "@/views/planning/CreateForm";
import PlanningList from "@/views/planning/PlanningList";
import ResultsImportationForm from "@/views/results/ImportationForm";
import SchoolFeesImportationForm from "@/views/school_fees/ImportationForm";
import ResultsList from '@/views/results/NoteList';
import SchoolFeesList from "@/views/school_fees/SchoolFeesList.vue";
import LoginForm from "@/views/auth/LoginForm";
import UpdateForm from "@/views/planning/UpdateForm";
import SchoolFeesUpdateForm from "@/views/school_fees/SchoolFeesUpdateForm";
import Display from "@/views/announcement/Display";
import CreateAnnouncementForm from "@/views/announcement/CreateAnnouncementForm";
import UpdateAnnouncementForm from "@/views/announcement/UpdateAnnouncementForm";
import DelayCreateForm from '@/views/delay/CreateForm';
import DelaysList from '@/views/delay/ListView';
import BooksList from '@/views/books/BooksList';
import BookCreateForm from '@/views/books/CreateForm';
import BooksCategories from '@/views/books/BooksCategories';
import BooksDomains from '@/views/books/BooksDomains';
import PasswordReset from '@/views/auth/PasswordReset';


function guardAuthentication(to, from, next)
{
    var user = localStorage.getItem('auth');
    var u = JSON.parse(user);



//this is just an example. You will have to find a better or 
// centralised way to handle you localstorage data handling 
    if(u.user.profile_id === 1){
        // console.log(u.user.student_reference_number);
        
        next();
    }
    else{
        next('/login');
    }
}

function guardAdmin(to, from, next) {
    var user = localStorage.getItem('auth');
    var u = JSON.parse(user);
    if(u.user.profile_id > 1){
        next();
    }
    else{
        next('/login');
    }
}

const routes = [
    {
        path: '/',
        name: 'Home',
        component: LoginForm
    },
    {
        path: '/password-reset',
        name: 'password-reset',
        component: PasswordReset
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    {
        path: '/login',
        name: 'LoginForm',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/auth/LoginForm.vue')
    },

    {
        path: '/admin-login',
        name: 'AdminLogin',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/auth/AdminLogin.vue')
    },

    {
        path: '/general',
        name: 'general',
        component: Master,
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                component: HomeView
            },
            {
                path: '/create-delay',
                name: 'create-delay',
                component: DelayCreateForm,
            },
        ]
    },

    {
        // Admin routes
        path: '/admin',
        name: 'admin',
        component: Master,
        beforeEnter: guardAdmin,
        children: [
            {
                path: '/delays',
                name: 'delays',
                component: DelaysList,
            },
            {
                path: '/create-book',
                name: 'create-book',
                component: BookCreateForm,
            },
            {
                path: '/create-planning',
                name: 'create-planning',
                component: PlanningCreateForm,
            },
            
            {
                path: '/update-planning',
                name: 'update-planning',
                component: UpdateForm,
            },
            {
                path: '/admin-plannings',
                name: 'admin-plannings',
                component: PlanningList
            },

            {
                path: '/import-results',
                name: 'import-results',
                component: ResultsImportationForm
            },
            {
                path: '/admin-results',
                name: 'admin-results',
                component: ResultsList
            },


            // Finance
            {
                path: '/admin-school-fees',
                name: 'admin-school-fees',
                component: SchoolFeesList
            },
            {
                path: '/admin-school-fees-create',
                name: 'admin-school-fees-create',
                component: SchoolFeesImportationForm
            },
            {
                path: '/update-school-fees',
                name: 'update-school-fees',
                component: SchoolFeesUpdateForm,
            },


            // Marketing
            {
                path: '/admin-announcements',
                name: 'admin-announcements',
                component: Display
            },
            {
                path: '/create-announcement',
                name: 'create-announcement',
                component: CreateAnnouncementForm,
            },
            {
                path: '/update-announcement',
                name: 'update-announcement',
                component: UpdateAnnouncementForm,
            },
        ]
    },

    {
        // Students routes
        path: '/main',
        name: 'main',
        component: Master,
        beforeEnter: guardAuthentication,
        children: [

            {
                path: '/book-categories',
                name: 'book-categories',
                component: BooksCategories
            },
            {
                path: '/book-domains',
                name: 'book-domains',
                component: BooksDomains
            },
            {
                path: '/books-list',
                name: 'books-list',
                component: BooksList
            },
            {
                path: '/weekly-planning',
                name: 'weekly-planning',
                component: PlanningDisplay
            },
            {
                path: '/announcements',
                name: 'announcements',
                component: AnnouncementDisplay
            },
            {
                path: '/school-fees',
                name: 'school-fees',
                component: SchoolFeesDisplay
            },
            {
                path: '/results',
                name: 'results',
                component: AcademicResultsDisplay
            },
            {
                path: '/student-planning',
                name: 'student-planning',
                component: StudentPlanning
            },
            {
                path: '/student-announcement',
                name: 'student-announcement',
                component: StudentAnnouncement
            },

        ]
    },


]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
