<template>
  <div class="container">

    <div class="row">
      <div class="col-md-4 col-lg-3"></div>

      <div class="col-md-4 col-lg-6 col-sm-12 card card-form">
        <form enctype="multipart/form-data" v-on:submit.prevent="createDelay">


            <div class="input-group mb-3">
                <div class="input-group-prepend">
                <span class="input-group-text">Numéro matricule</span>
                </div>
                <input v-model="student_reference_number" v-on:keyup="getStudentByReference($event.target.value)" class="form-control" placeholder="Ex: 22BM1054" type="text">
                
            </div>
            <div style="color: #DC0000;" class="input-group mb-3">
                <span><b>Entrez votre matricule et votre nom apparaîtra dans le champ en dessous!</b></span>
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                <span class="input-group-text">Nom et prénoms</span>
                </div>
                <input v-model="name" class="form-control" placeholder="Nom" type="text" disabled>
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                <span class="input-group-text">Heure début du cours</span>
                </div>
                <input v-model="start_time" class="form-control" placeholder="Ex: 09:00" type="time">
            </div>

            
            <div class="input-group mb-3">
                <textarea name="reason" id="reason" v-model="reason" class="form-control" placeholder="Pourquoi êtes-vous en retard?" cols="30" rows="10"></textarea>
            </div>

            <button class="btn btn-primary btn-lg" type="button" v-on:click="createDelay">Ajouter</button>

        </form>
      </div>
      <div class="col-md-4 col-lg-3"></div>

    </div> 
  </div>

</template>

<script>

import axios from "axios";

export default {
  name: 'CreatePlanning',
  components: {},
  data() {
    return {
      student_reference_number: '',
      reason: '',
      start_time: null,
      name: '',
      level: '',
      student: null,
    };
  },
  mounted() {
    this.$store.state.pageName = "Formulaire de retard";
    

  },

  methods: {
    createDelay() {
    if (this.name) {
        let formData = new FormData();

        formData.append('student_reference_number', this.student_reference_number);
        formData.append('reason', this.reason);
        formData.append('start_time', this.start_time);

        axios.post('api/delay/store', formData, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
        }).then(
            result => {
                var message = '';
                let retard = result.data;
                // console.log(result.data.id);
                if (result.data.id) {
                    message = "Retard bien enregistré pour " + retard.first_name + " " + retard.last_name;
                } else {
                    message = "Echec de l\'enregistrement";
                }
                alert(message);
                // console.log(message);
                this.$router.push("/login");
            }
        )
    }else{
        alert("Echec de l'enregistrement");

    }
        
    },

    getStudentByReference(referenceNumber){
        axios.get('api/student/get-by-reference/' + referenceNumber).then(result=>{
            this.student = result.data;
            this.name = this.student.last_name + ' ' + this.student.first_name;
        });
    }

  }
}
</script>

<style>
.slider-frame {
  max-height: 600px;
}
</style>