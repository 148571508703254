import { createStore } from 'vuex'

export default createStore({
  state: {
    authenticated: false,
    user: null,
    pageName: "Nom de la page",
    admin: 0,
    adminProfile: null
  },
  getters: {
  },
  mutations: {
    setAuth(state, user){
      localStorage.setItem('auth', JSON.stringify(user));
      state.authenticated = true;
      state.user = user;

      
      
    },

    initializeAuth(state){
      if (localStorage.getItem('auth')) {
        state.authenticated = true;
        state.user = JSON.parse(localStorage.getItem('auth'));
      }
    },
    logout(state){
      state.authenticated = false;
      state.user = null;
    }
  },
  actions: {
  },
  modules: {
  }
})
