<template>
  <div class="">
    <div class="row">
      <div class="col-lg-10">
        <!-- <h1>Plannings</h1> -->
      </div>

      <div class="col-lg-2">
        <button
            class="btn btn-primary mr-1"
            type="button"
            v-on:click="addPlanning"
        >
        <i class="fas fa-calendar"></i>
          Nouveau planning
        </button>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table table-hover table-sm table-bordered">
            <thead class="thead-dark">
            <tr>
              <td>Niveaux</td>
              <td>Messages</td>
              <td>Périodes</td>
              <td>Publié</td>
              <td>Actions</td>
            </tr>
            </thead>

            <tbody>
            <tr v-for="p in plannings" v-bind:key="p">
              <td>{{ p.level_code }}</td>
              <td>{{ p.message }}</td>
              <td><b>De</b> {{ p.start_date }} <b>à</b> {{ p.end_date }}</td>
              <td>
                  <span class="badge badge-pill badge-primary">{{
                      p.published == 1 ? "Oui" : "Non"
                    }}</span>
              </td>
              <td class="text-lg-left">
                <a href="#" v-on:click="updatePlanning(p.planning_id)"><i class="fas fa-pen"></i></a>
                |
                <a style="color: red;" href="#" v-on:click="deletePlanning(p.planning_id)"><i class="fas fa-trash"></i></a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PlanningList",
  props: ['planning_id'],
  data() {
    return {
      plannings: [],
      deleteResult: null,
      status: null,
      message: null,
    };
  },

  mounted() {
    this.$store.state.pageName = "Plannings";
    this.getPlannings();
  },

  methods: {
    addPlanning() {
      this.$router.push("/create-planning");
    },

    updatePlanning(id) {
      this.$router.push({
        name: "update-planning", params: {
          planning_id: id
        }
      });
    },

    getPlannings() {
      axios.get("/api/plannings/all").then((plannings) => {
        this.plannings = plannings.data.plannings;
      });
    },

    editPlanning(id) {
      axios.get("/api/planning/edit/" + id).then((plannings) => {
        this.plannings = plannings.data.plannings;

        this.getPlannings();
      });
    },

    deletePlanning(id) {
      if (confirm("Etes-vous sûr de vouloir supprimer cet élément ?")) {
        axios.get("/api/planning/delete/" + id).then((result) => {
          this.deleteResult = result.data;
          console.log(result.data);

          if (result.data.status === 200) {
            console.log(result.data.message)
            this.$router.push("/admin-plannings");
            alert(result.data.message);
            this.getPlannings();
          } else {
            console.log(result.data.message)
          }
        });
      }
    },
  },
};
</script>

<style scoped>
</style>