<template>
  <div>
    <header>
      
      <p>
        Bienvenu(e) sur NOTODJI : votre canal d’informations à LBS,
      </p>
    </header>

    <div v-if="this.$store.state.user.profile != null">
      <div class="row">
      
      <div v-on:click="adminPlannings" class="col-sm-12 col-md-4">
        <a href="#">
          <div class="container">
            <img src="images/pic04.jpg" alt="" class="image dashboard-img">
            <div class="centered"><h1>Planning</h1></div>
            <div class="middle">
              <div class="text">Programmez les cours et ateliers.</div>
            </div>
          </div>
        </a>
        <hr>
      </div>

      <div class="col-sm-12 col-md-4">
        <a v-on:click="adminSchoolFees" href="#">
          <div class="container">
            <img src="images/pic05.jpg" alt="" class="image dashboard-img">
            <div class="centered"><h1>Scolarité</h1></div>
            <div class="middle">
              <div class="text">Suivez les paiements de scolarité des étudiants</div>
            </div>
          </div>
        </a>
        <hr>
      </div>

      <div class="col-sm-12 col-md-4">
        <a v-on:click="adminAnnouncements" href="#">
          <div class="container">
            <img src="images/pic06.jpg" alt="" class="image dashboard-img">
            <div class="centered"><h1>Annonces</h1></div>
            <div class="middle">
              <div class="text">Programmez le passage des annonces</div>
            </div>
          </div>
        </a>
        <hr>
      </div>
      
    </div>
    <div class="row">
      
      <div v-on:click="adminResults" class="col-sm-12 col-md-4">
        <a href="#">
          <div class="container">
            <img src="images/pic04.jpg" alt="" class="image dashboard-img">
            <div class="centered"><h1>Notes</h1></div>
            <div class="middle">
              <div class="text">Publiez les résultats académiques.</div>
            </div>
          </div>
        </a>
        <hr>
      </div>

      <div v-on:click="delays" class="col-sm-12 col-md-4">
        <a href="#">
          <div class="container">
            <img src="images/pic05.jpg" alt="" class="image dashboard-img">
            <div class="centered"><h1>Retards</h1></div>
            <div class="middle">
              <div class="text">Suivez les retardataires.</div>
            </div>
          </div>
        </a>
        <hr>
      </div>
      <div v-on:click="createBook" class="col-sm-12 col-md-4">
        <a href="#">
          <div class="container">
            <img src="images/pic05.jpg" alt="" class="image dashboard-img">
            <div class="centered"><h1>Bibliothèque</h1></div>
            <div class="middle">
              <div class="text">Gérez les documents physiques et numériques</div>
            </div>
          </div>
        </a>
        <hr>
      </div>

      
    </div><br>
    </div>
    <div v-else>
      <div class="row">
      
        <div class="col-sm-12 col-md-4">
          <a v-on:click="planningPage" href="#">
          <div class="container">
            <img src="images/pic01.jpg" alt="" class="image dashboard-img">
            <div class="centered"><h1>Planning</h1></div>
            <div class="middle">
              <div class="text">Consultez votre emploi du temps hebdomadaire</div>
            </div>
          </div>
          </a>
      
        <hr>
        </div>
      

        <div class="col-sm-12 col-md-4">
          <a v-on:click="resultsPage" href="#">
            <div class="container">
              <img src="images/pic02.jpg" alt="" class="image dashboard-img">
              <div class="centered"><h1>Notes</h1></div>
              <div class="middle">
                <div class="text">Consultez vos résultats académiques</div>
              </div>
            </div>
          </a>
          <hr>
        </div>

        <div class="col-sm-12 col-md-4">
          <a v-on:click="announcementPage" href="#">
            <div class="container">
              <img src="images/pic03.jpg" alt="" class="image dashboard-img">
              <div class="centered"><h1>Annonces</h1></div>
              <div class="middle">
                <div class="text">Recevez les actualités de Lomé Business School</div>
              </div>
            </div>
          </a>
          <hr>
        </div>
      
    </div>

    <div class="row">


      <div class="col-sm-12 col-md-4">
        <a v-on:click="schoolFeesPage" href="#">
          <div class="container">
            <img src="images/pic05.jpg" alt="" class="image dashboard-img">
            <div class="centered"><h1>Scolarité</h1></div>
            <div class="middle">
              <div class="text">Suivez vos paiements de la scolarité</div>
            </div>
          </div>
        </a>
        <hr>
      </div>
            
      <div class="col-sm-12 col-md-4">
          <a v-on:click="libraryPage" href="#">
            <div class="container">
              <img src="images/pic06.jpg" alt="" class="image dashboard-img">
              <div class="centered"><h1>Bibliothèque</h1></div>
              <div class="middle">
                <div class="text">Accédez à la bibliothèque numérique et empruntez des documents physiques</div>
              </div>
            </div>
          </a>
          <hr>
        </div>
        
        <div class="col-sm-12 col-md-4">
        <a href="https://lome-bs.com">
          <div class="container">
            <img src="images/pic08.jpg" alt="" class="image dashboard-img">
            <div class="centered"><h1>Site web</h1></div>
            <div class="middle">
              <div class="text">Visitez le site web de Lomé Business School</div>
            </div>
          </div>
        </a>
        <hr>
      </div>
    </div>
      </div>

      


    <div class="row">

      
          
      <!-- <div v-on:click="belbsPage" class="col-sm-12 col-md-4">
        <a href="#">
          <div class="container">
            <img src="images/pic04.jpg" alt="" class="image dashboard-img">
            <div class="centered"><h1>BeLBS</h1></div>
            <div class="middle">
              <div class="text">Suivez les activités du Bureau des étudiants.</div>
            </div>
          </div>
        </a>
        <hr>
      </div>


      <div v-on:click="itServicePage" class="col-sm-12 col-md-4">
        <a href="#">
          <div class="container">
            <img src="images/pic09.jpg" alt="" class="image dashboard-img">
            <div class="centered"><h1>Service Informatique</h1></div>
            <div class="middle">
              <div class="text">Sollicitez le service informatique</div>
            </div>
          </div>
        </a>
        <hr>
      </div> -->
      
    </div>

      <!-- <div class="row">
        
        <div v-on:click="kreacityPage" class="col-sm-12 col-md-4">
          <a href="#">
            <div class="container">
              <img src="images/pic07.jpg" alt="" class="image dashboard-img">
              <div class="centered"><h1>INCUBATEUR</h1></div>
              <div class="middle">
                <div class="text">Actualité de l’incubateur LBS : KREACITY</div>
              </div>
            </div>
          </a>
          <hr>
        </div>

        <div class="col-sm-12 col-md-4">
          <a v-on:click="canteenPage" href="#">
            <div class="container">
              <img src="images/pic08.jpg" alt="" class="image dashboard-img">
              <div class="centered"><h1>CANTINE</h1></div>
              <div class="middle">
                <div class="text">Consultez les menus de la semaine</div>
              </div>
            </div>
          </a>
          <hr>
        </div>

        <div class="col-sm-12 col-md-4">
          <a v-on:click="sickBayPage" href="#">
            <div class="container">
              <img src="images/pic09.jpg" alt="" class="image dashboard-img">
              <div class="centered"><h1>INFIRMERIE</h1></div>
              <div class="middle">
                <div class="text">...</div>
              </div>
            </div>
          </a>
          <hr>
        </div>
        
      </div> -->
    
    
    
  </div>
</template>
<script>
// @ is an alias to /src
// import Header from "@/components/Header.vue";

export default {
  name: "HomeView",
  components: {
    // Header,
  },
  data(){
    return {

    }
  },

  mounted(){
    this.$store.state.pageName = "Accueil";
  },

  methods: {
    // Admin
    delays(){
      this.$router.push('delays');
    },
    adminPlannings(){
      this.$router.push('admin-plannings');
    },
    adminSchoolFees(){
      this.$router.push('admin-school-fees');
    },
    adminAnnouncements(){
      this.$router.push('admin-announcements');
    },
    adminResults(){
      this.$router.push('admin-results')
    },
    schoolFeesPage(){
      this.$router.push('school-fees');
    },
    resultsPage(){
      this.$router.push('results');
    },
    planningPage(){
      this.$router.push('student-planning');
    },
    announcementPage(){
      this.$router.push('announcements');
    },
    libraryPage(){
      this.$router.push('book-categories');
    },
    createBook(){
      this.$router.push('create-book');
    },
    itServicePage(){

    },
    kreacityPage(){

    },
    canteenPage(){

    },
    sickBayPage(){

    },
    belbsPage(){

    }
    
  }
};
</script>
