<template>
    <div class="row">
        <div class="col-12">
            <SlideShow/>
        </div>  
    </div>
</template>

<script>
import SlideShow from '@/components/SlideShow.vue';

export default {
    name: 'DisplayView',
    components: {
        SlideShow
    }
}
</script>

<style>
.slider-frame{
    max-height: 600px;
}
</style>