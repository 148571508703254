<template>
<div class="container">

    <div class="row">
    <div class="col-md-4 col-lg-3">
        <button v-if="this.$store.state.user.profile != null" class="btn btn-primary mr-1" type="button" v-on:click="addCategory">
        <i class="fas fa-calendar"></i>
          Nouveau document
        </button>
        <span v-else></span>
    </div>

    <div class="col-md-4 col-lg-6 col-sm-12 card card-form">
        <div v-for="category in categories" v-bind:key="category">
            <div v-on:click="domainsPage(category.id)" class="card bg-light text-dark">
                <div class="card-body">{{category.label}}</div>
            </div>
            <br>
        </div>
    </div>
    <div class="col-md-4 col-lg-3"></div>

    </div> 
</div>

</template>

<script>

import axios from "axios";

export default {
name: 'BooksCatgories',
components: {},
data() {
    return {
    categories: null,

    };
},
mounted() {
    this.$store.state.pageName = "Catégories de documents";
    this.getCategories();

},

methods: {

    getCategories() {
    axios.get('api/book-categories/index',).then(
        result => {
            this.categories = result.data;
        }
    )
    },
    
    domainsPage(categoryId){
        this.$router.push({
            name:'book-domains',
            params: {categoryId: categoryId}
        })
    },

    addCategory(){

    }

}
}
</script>

<style>
.slider-frame {
max-height: 600px;
}
</style>