<template>
    <div>
        
        
        <!-- <hr>
        <div class="row">
            <div class="col-md-2 col-sm-2"></div>
            <div class="col-md-4 col-sm-4">
                <form class="form-inline" action="">
                    <label for="acadmeic_year">Année:</label>
                    <select class="form-control" id="sel1">
                        <option>2021-2022</option>
                        <option>2020-2021</option>
                    </select>
                </form>
            </div>
            <div class="col-md-4 col-sm-4">Crédits validés: </div>
            <div class="col-md-2 col-sm-2"></div>

        </div> -->
        <br>
        <div class="row">
            <div class="col-12">
                <table class="table table-hover">
                    <thead class="thead-dark">
                        <tr>
                            <th>Intitulé module</th>
                            <th>Niveau</th>
                            <th>Semestre</th>
                            <th>Crédit</th>
                            <th>Devoir (40%)</th>
                            <th>Examen (60%)</th>
                            <th>Moyenne / 20</th>
                            <th>Résultat</th>
                            <!-- <th>Mention</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="result in results" v-bind:key="result" :class="[result.status == 'FAIT' ? [result.result == 'Admis'? 'table-success': 'table-danger'] : 'table-warning']">
                            <td style="text-align: left;">{{result.module}}</td>
                            <td>{{result.level}}({{result.academic_year}})</td>
                            <td>{{result.semester}}</td>
                            <td>{{result.credit}}</td>
                            <td>{{result.assignment_mark}}</td>
                            <td>{{result.exam_mark}}</td>
                            <td>{{result.average}}</td>
                            <td v-if="result.status == 'FAIT'">{{result.result}}</td>
                            <td v-else>Examen en attente</td>
                            <!-- <td>{{result.appreciation}}</td> -->
                        </tr>
                        
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    
</template>

<script>
import axios from 'axios';

export default {
    name: 'DisplayView',
    components: {
    },
    data(){
        return{
            studentId : null,
            results: []
        };
    },
    mounted(){
        this.studentId = this.$store.state.user.user.student_reference_number,
        this.$store.state.pageName = "Mes notes";
        this.getStudentResults();

    },
    methods:{
        getStudentResults(){
            axios.get('/api/result/get-result-by-student-id/'+this.studentId).then(result=>{
                this.results = result.data.results
            })
        }

    }
}
</script>

<style>

</style>