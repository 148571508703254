<template>


  <div id="exampleModal" aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" role="dialog"
       tabindex="-1">
    <div class="modal-dialog" role="document">
      <form enctype="multipart/form-data" v-on:submit.prevent="importResult">

        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">Importer depuis un fichier excel</h5>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <div class="custom-file">
              <input id="fichier" ref="file" class="custom-file-input" type="file" v-on:change="handleFile">
              <label v-if="file" class="custom-file-label" for="customFile">{{ file.name }}</label>
              <label class="custom-file-label" for="customFile" v-else>Importer par fichier excel</label>
            </div>

          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" type="submit">Charger</button>
            <button class="btn btn-secondary" data-dismiss="modal" type="button">Annuler</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-md-4 col-lg-3">
        <button class="btn btn-primary" data-target="#exampleModal" data-toggle="modal"
                type="button">Importer un fichier excel
        </button>

      </div>

      <div class="col-md-4 col-lg-6 col-sm-12 card card-form">
        <form enctype="multipart/form-data" v-on:submit.prevent="createResult">

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Niveau</span>
            </div>
            <select v-model="level_id" class="custom-select">
              <option v-for="level in levels" v-bind:key="level" v-bind:value="level.id">{{ level.label }}</option>
            </select>
          </div>

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Matricule</span>
            </div>
            <input v-model="reference_number" class="form-control" placeholder="22B1SI098" type="text">
          </div>


          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Nom</span>
            </div>
            <input v-model="last_name" class="form-control" placeholder="KOKOU" type="text">
          </div>


          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Prénon(s)</span>
            </div>
            <input v-model="first_name" class="form-control" placeholder="Koffi Kodjo" type="text">
          </div>


          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Module</span>
            </div>
            <input v-model="module" class="form-control" placeholder="Application web & mobile" type="text">
          </div>

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Note de devoir</span>
            </div>
            <input v-model="assignment_mark" class="form-control" placeholder="12" type="number">
          </div>

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Note d'examen</span>
            </div>
            <input v-model="exam_mark" class="form-control" placeholder="12" type="number">
          </div>

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Moyenne</span>
            </div>
            <input v-model="average" class="form-control" placeholder="12" type="number">
          </div>

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Résultat</span>
            </div>
            <select v-model="result" class="custom-select">
              <option v-bind:value="valid">Valider</option>
              <option v-bind:value="reject">Rejeter</option>
            </select>
          </div>

          <div class="input-group mb-3">
            <textarea id="appreciation" v-model="appreciation" class="form-control" cols="5"
                      name="appreciation" placeholder="Appréciation " rows="5"></textarea>
          </div>


          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Année académique</span>
            </div>
            <input v-model="academic_year" class="form-control" placeholder="2021 - 2022" type="text">
          </div>

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Semestre</span>
            </div>
            <input v-model="semester" class="form-control" placeholder="12" type="number">
          </div>

          <br>
          <br>
          <!--          <div align="left" class="custom-control custom-checkbox">-->
          <!--            <input id="customCheck" v-model="published" class="custom-control-input" type="checkbox" value="">-->
          <!--            <label class="custom-control-label" for="customCheck">Publier ?</label>-->
          <!--          </div>-->
          <!--          <br>-->

          <button class="btn btn-primary btn-lg" type="submit">Ajouter</button>

        </form>
      </div>

      <div class="col-md-4 col-lg-3"></div>

    </div>
  </div>

</template>

<script>

import axios from "axios";

export default {
  name: "ImportationForm",
  data() {
    return {
      levels: null,
      level: null,
      level_id: 0,
      reference_number: null,
      first_name: null,
      last_name: null,
      module: null,
      assignment_mark: null,
      exam_mark: 0,
      average: 0,
      result: null,
      semester: null,
      status: "FAIT",
      appreciation: null,
      academic_year: null,
      admin_id: null,
      published: false,
      file: null
    };
  },

  mounted() {
    this.$store.state.pageName = "Ajouter une note";
    this.getLevels();
  },

  methods: {
    getLevels() {
      axios.get('api/levels/all',).then(
          result => {
            this.levels = result.data.levels
          }
      )
    },

    importResult() {

      let formData = new FormData();

      formData.append('fichier', this.file);
      console.log(formData);
      axios.post('api/result/store', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(
          result => {
            console.log(result.data)
            alert(result.data.message)
          }
      )
    },

    createResult() {
      let formData = new FormData();

      formData.append('student_reference_number', this.reference_number);
      formData.append('level', this.level_id);
      formData.append('module', this.module);
      formData.append('last_name', this.last_name);
      formData.append('first_name', this.first_name);
      formData.append('assignment_mark', this.assignment_mark);
      formData.append('exam_mark', this.exam_mark);
      formData.append('average', this.average);
      formData.append('result', this.result);
      formData.append('academic_year', this.academic_year);
      formData.append('semester', this.semester);
      formData.append('appreciation', this.appreciation);
      formData.append('status', this.status);

      axios.post('api/result/create', formData).then(
          result => {
            alert(result.data.message);
            this.$router.push("/admin-results");
          }
      )
    },
    handleFile() {
      this.file = this.$refs.file.files[0];
    },

  }
}
</script>

<style scoped>

</style>