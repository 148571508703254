<template>
<!--  <h2>NOTODJI APP</h2>-->
<!--  <h4>Soyez à l'écoute 😃</h4>-->

<!--  <nav class="navbar navbar-expand-sm bg-light navbar-dark">-->
<!--    <ul class="navbar-nav">-->
<!--      <li class="nav-item m-1">-->
<!--        <router-link class="btn btn-light btn-outline-primary"-->
<!--                     to="/admin">Home-->
<!--        </router-link>-->
<!--      </li>-->
<!--      <li class="nav-item m-1">-->
<!--        <router-link class="btn btn-light btn-outline-primary"-->
<!--                     to="/admin.announcements">Announcements-->
<!--        </router-link>-->
<!--      </li>-->
<!--      <li class="nav-item m-1">-->
<!--        <router-link class="btn btn-light btn-outline-primary"-->
<!--                     to="/admin.plannings">Plannings-->
<!--        </router-link>-->
<!--      </li>-->
<!--    </ul>-->
<!--  </nav>-->
<!--  &lt;!&ndash; <nav>-->
<!--    <router-link to="/">Home</router-link> |-->
<!--    <router-link to="/about">About</router-link> |-->
<!--    <router-link to="/login">Login</router-link>-->
<!--  </nav> &ndash;&gt;-->
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f5f7fa;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
