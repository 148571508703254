<template>
    <div>
        <div class="row">
            <div class="col-3"></div>
            <div class="col-6 input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">Choisissez un niveau</span>
                </div>
                <select v-model="level" @change="getPlanningByLevel()" class="custom-select">
                    <option v-for="level in levels" v-bind:key="level" v-bind:value="level.id">{{ level.label }}</option>
                </select>
            </div>
            <div class="col-3"></div>

        </div>
        <div class="row">
            <div v-for="planning in plannings" v-bind:key="planning" class="col-12">
                <img v-bind:src="'https://back-notodji.lome-bs.com/my_app/storage/app/public/' + planning.image_url" alt="">
            </div>  
        </div>
    </div>
</template>

<script>
import axios from 'axios';


export default {
    name: 'StudentPlanning',
    components: {
        
    },
    data(){
        return{
            studentLevel : this.$store.state.user.user.level_id,
            plannings: [],
            levels: [],
            level: null,
        };
    },
    mounted(){
        this.$store.state.pageName = "Mon planning de cours"
        // this.getPlanningByLevel();
        this.getLevels();

    },
    methods:{
        getLevels(){
            axios.get('/api/levels/all')
            .then(result=>{
                this.levels = result.data.levels;
            });
        },
        getPlanningByLevel(){
            
            axios.get('/api/planning/get-week-active-planning-by-level/'+this.level)
            .then(result=>{
                this.plannings = result.data.plannings;

            });
        }
    }
}
</script>

<style>
.slider-frame{
    max-height: 600px;
}
</style>