<template>
  <div id="demo" class="carousel slide" data-ride="carousel" data-interval="10000">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="row">
          
          <div class="col-6 img-frame">
            <img class="carousel_img img-rounded" v-bind:src="'https://back-notodji.lome-bs.com/my_app/storage/app/public/' + ann.url">
          </div>
          <div class="col-6">
            <div>
              <h2 class="announcement_title">{{ann.title}}</h2>
            </div>
            <div class="card">
              <div class="card-body">
                <p class="announcement_description">
                  {{ann.description}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-for="announcement in announcements" v-bind:key="announcement" class="carousel-item">
        <div class="row">
          
          <div class="col-6 img-frame">
            <img class="carousel_img img-rounded" v-bind:src="'https://back-notodji.lome-bs.com/my_app/storage/app/public/' + announcement.url">
          </div>
          <div class="col-6">
            <div>
              <h1 class="announcement_title">{{announcement.title}}</h1>
            </div>
            <div class="card">
              <div class="card-body">
                <p class="announcement_description">
                  {{announcement.description}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    
    
  </div>
    <!-- <carousel :items-to-show="1" :autoplay="20000" :wrap-around="true" :snap-align="center">
      <slide class="" v-for="announcement in announcements" v-bind:key="announcement">
        
        <div class="row">

          <div class="col-6 img-frame">
            <img class="carousel_img img-rounded" v-bind:src="'https://back-notodji.lome-bs.com/my_app/storage/app/public/' + announcement.url">
          </div>
          <div class="col-6">
            <div>
              <h1 class="announcement_title">{{announcement.title}}</h1>
            </div>
            <div class="card">
              <div class="card-body">
                <p class="announcement_description">
                  {{announcement.description}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </slide>
      <template #addons>
        <navigation />
        <pagination />
      </template>
    </carousel> -->
  
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel';
import MarqueeText from 'vue-marquee-text-component'
import axios from 'axios';

export default {
  name: "SlideShow",
  components: {
    Carousel,
    Slide,
    
    MarqueeText,
  },

  data() {
    
    return {
      
      announcements:[],
      ann:[]
    };
  },
  mounted() {
    this.getAnnouncements();
  },
  methods: {
    getAnnouncements() {
      axios.get("/api/announcements/all").then((response) => {
        // console.log(response);
        this.announcements = response.data.announcements;
        this.ann = this.announcements[0];
        console.log(this.ann);
        
      });
    },
  },
};
</script>

<style scoped>
.marquee-message{
  position: relative;
  bottom: 0px;
  z-index: 1;
  background-color: darkblue;
  color: white;
  font-size: 200%;
}

.carousel_img img{
  height: 200 px;
}
.carousel_img{
  width:100% ;
  border-radius: 20px;
  box-shadow: 6px 5px 5px black;
}
.announcement_title{
  font-size: 500%;
  color: rgb(233, 130, 130);
}
.announcement_description{
  font-size: 200%;
  font-family: monospace;
  font-style: oblique;
  width: 100%;
  animation-duration: 10s;
  animation-name: slidein;
  animation-iteration-count: 1;
  text-align: justify;
}

/* @keyframes slidein {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
} */
</style>