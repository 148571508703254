<template>
  <div class="container">

    <div class="row">
      <div class="col-md-4 col-lg-3"></div>

      <div class="col-md-4 col-lg-6 col-sm-12 card card-form">
        <form enctype="multipart/form-data" v-on:submit.prevent="updateSchoolFees">

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Matricule</span>
            </div>
            <input v-model="student_reference_number" class="form-control" placeholder="" type="text">
          </div>

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Année académique</span>
            </div>
            <input v-model="academic_year" class="form-control" placeholder="" type="text">
          </div>


          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Nom </span>
            </div>
            <input v-model="last_name" class="form-control" placeholder="" type="text">
          </div>

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Prénom(s)</span>
            </div>
            <input v-model="first_name" class="form-control" placeholder="" type="text">
          </div>


          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Montant payé</span>
            </div>
            <input v-model="total_paid" class="form-control" placeholder="" type="number">
          </div>

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Date de paiement</span>
            </div>
            <input v-model="payment_date" class="form-control" placeholder="" type="date">
          </div>

          <button class="btn btn-primary btn-lg" type="button" v-on:click="updateSchoolFees">Modifier</button>

        </form>
      </div>
      <div class="col-md-4 col-lg-3"></div>

    </div>
  </div>

</template>

<script>

import axios from "axios";

export default {
  name: 'UpdateSchoolFees',
  components: {},
  data() {
    return {
      student_reference_number: null,
      academic_year: null,
      last_name: null,
      first_name: null,
      total_paid: null,
      payment_date: null,
    };
  },
  mounted() {
    this.$store.state.pageName = "Mise à jour scolarité";
    this.getSchoolFees(this.$route.params.school_fees_id);
    
    this.schoolFeesId = this.$route.params.school_fees_id;
    // console.log(this.$route.params.school_fees_id);
  },

  methods: {
    updateSchoolFees() {
      let formData = new FormData();

      formData.append('student_reference_number', this.student_reference_number);
      formData.append('academic_year', this.academic_year);
      formData.append('first_name', this.first_name);
      formData.append('last_name', this.last_name);
      formData.append('payment_date', this.payment_date);
      formData.append('total_paid', this.total_paid);

      axios.post('api/school-fees/update/' + this.schoolFeesId, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(
          result => {
            alert(result.data.message);
            this.file = null;
            this.$router.push("/admin-school-fees");
          }
      )
    },

    handleFile() {
      this.file = this.$refs.file.files[0];
    },

    getSchoolFees(id) {
      axios.get('api/school-fees/edit/' + id).then(
          result => {
            console.log(result.data)

            this.student_reference_number = result.data.school_fees.student_reference_number;
            this.academic_year = result.data.school_fees.academic_year;
            this.last_name = result.data.school_fees.last_name;
            this.first_name = result.data.school_fees.first_name;
            this.total_paid = result.data.school_fees.total_paid;
            this.payment_date = result.data.school_fees.payment_date;

          }
      )
    }
  }
}
</script>

<style>
.slider-frame {
  max-height: 600px;
}
</style>