<template>
  <div class="container">

    <div class="row">
      <div class="col-md-4 col-lg-3"></div>

      <div class="col-md-4 col-lg-6 col-sm-12 card card-form">
        <form enctype="multipart/form-data" v-on:submit.prevent="createAnnouncement">

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Catégorie</span>
            </div>
            <select v-model="category_id" class="custom-select">
              <option v-for="category in categories" v-bind:key="category" v-bind:value="category.id">
                {{ category.label }}
              </option>
            </select>
          </div>

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Titre</span>
            </div>
            <input v-model="title" class="form-control" placeholder="Titre de l'annonce" type="text">
          </div>

          <div class="input-group mb-3">
            <textarea id="description" v-model="description" class="form-control" cols="30" name="description"
                      placeholder="Saisissez une description " rows="10"></textarea>
          </div>


          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Date de debut</span>
            </div>
            <input v-model="start_date" class="form-control" placeholder="06/12/2022" type="date">
          </div>

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Date de fin</span>
            </div>
            <input v-model="end_date" class="form-control" placeholder="06/12/2022" type="date">
          </div>

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Type</span>
            </div>
            <input v-model="type" class="form-control" placeholder="IMAGE" type="text">
          </div>

          <div class="custom-file">
            <input id="image" ref="file" class="custom-file-input" type="file" v-on:change="handleFile">
            <label v-if="file" class="custom-file-label" for="customFile">{{ file.name }}</label>
            <label v-else class="custom-file-label" for="customFile">Joindre l'image</label>
          </div>

          <br>
          <br>
          <div align="left" class="custom-control custom-checkbox">
            <input id="customCheck" v-model="published" class="custom-control-input" type="checkbox" value="">
            <label class="custom-control-label" for="customCheck">Publier ?</label>
          </div>
          <br>

          <button class="btn btn-primary btn-lg" type="button" v-on:click="updateAnnouncement">Modifier</button>

        </form>
      </div>
      <div class="col-md-4 col-lg-3"></div>

    </div>
  </div>

</template>

<script>

import axios from "axios";

export default {
  name: 'UpdateAnnouncement',
  components: {},
  data() {
    return {
      title: null,
      description: null,
      start_date: null,
      end_date: null,
      type: null,
      category_id: null,
      url: null,
      published: false,
      published_status: 0,
      file: null,
      admin_id: null,
      categories: [],
    };
  },
  mounted() {
    this.$store.state.pageName = "Mise à jour d'une annonce";
    this.getCategories();
    this.getAnnouncements(this.$route.params.announcement_id)
    // console.log(this.$route.params);
    this.announcementId = this.$route.params.announcement_id;
    console.log(this.$route.params.announcement_id);
  },

  methods: {
    updateAnnouncement() {
      let formData = new FormData();
      this.published ? this.published_status = 1 : this.published_status = 0;

      formData.append('title', this.title);
      formData.append('description', this.description);
      formData.append('category_id', this.category_id);
      formData.append('start_date', this.start_date);
      formData.append('end_date', this.end_date);
      formData.append('type', this.type);
      formData.append('url', this.file);
      formData.append('published', this.published_status);
      formData.append('admin_id', 1);
      console.log(this.published_status);

      axios.post('api/announcement/update/' + this.announcementId, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(
          result => {
            alert(result.data.message);
            this.file = null;
            this.$router.push("/admin-announcements");
          }
      )
    },

    handleFile() {
      this.file = this.$refs.file.files[0];
    },

    getAnnouncements(id) {
      axios.get('api/announcement/edit/' + id).then(
          result => {
            this.announcement = result.data.announcement;

            this.start_date = this.announcement.start_date;
            this.end_date = this.announcement.end_date;
            this.title = this.announcement.title;
            this.type = this.announcement.type;
            this.description = this.announcement.description;

            console.log(this.announcement)
          }
      )
    },
    getCategories() {
      axios.get("/api/categories/all").then((categories) => {
        this.categories = categories.data.categories;
      });
    },
  }
}
</script>

<style>
.slider-frame {
  max-height: 600px;
}
</style>