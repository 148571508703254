<template>
<div class="container">

    <div class="row">
    <div class="col-md-4 col-lg-3"></div>

    <div class="col-md-4 col-lg-6 col-sm-12 card card-form">
        <div v-for="domain in domains" v-bind:key="domain">
            <div v-on:click="booksList(domain.id)" class="card bg-light text-dark">
                <div class="card-body">{{domain.label}}</div>
            </div>
            <br>
        </div>
    </div>
    <div class="col-md-4 col-lg-3"></div>

    </div> 
</div>

</template>

<script>

import axios from "axios";

export default {
name: 'BooksDomains',
components: {},
data() {
    return {
    domains: null,
    category_id: 0,
    domain_id: 0,

    };
},
mounted() {
    this.$store.state.pageName = "Domaines";
    this.getDomains();

},

methods: {

    getDomains() {
        this.category_id = this.$route.params.categoryId;
        axios.get('api/book-domains/index',).then(
            result => {
                this.domains = result.data;
            });
    },
    
    booksList(domainId){
        this.$router.push({
            name:'books-list',
            params: {categoryId: this.category_id, domainId: domainId}
        });
    }

}
}
</script>

<style>
.slider-frame {
max-height: 600px;
}
</style>